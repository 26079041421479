@import 'main.scss';
.stepText {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--primary);
  @media screen and (min-width: 1000px) {
    text-align: center;
    align-items: center;
  }

  h2 {
    color: var(--primary);
    font-family: var(--font-avant);
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    padding-right: 72px;
    -webkit-text-stroke: 1px var(--primary);

    @media screen and (min-width: 1000px) {
      padding-right: 0;
      font-size: 50px;
      line-height: 60px;
      max-width: 740px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 60px;
      line-height: 70px;
      max-width: 870px;
    }

    @media screen and (min-width: 1600px) {
      font-size: 70px;
      line-height: 80px;
      max-width: 1100px;
      margin: auto;
    }
  }

  p {
    font-family: var(--font-sora);
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;

    @media screen and (min-width: 1000px) {
      font-size: 26px;
      line-height: 33px;
    }
  }
}

.btnContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: 1000px) {
    flex-direction: row;
    gap: 24px;
    justify-content: center;
  }

  .fillableButton {
    position: relative;
    padding: 18px;
    border-radius: 500px;
    background: white;
    overflow: hidden;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: var(--primary);
    border: 1px solid var(--primary);
    cursor: none;
    width: 100%;

    @media screen and (min-width: 1000px) {
      padding: 20px 50px;
      width: fit-content;
    }

    &.selected {
      background: #af2eff;
      border: 1px solid #af2eff;

      .buttonText {
        color: white;
      }
    }

    @media screen and (hover: hover) {
      &:hover {
        border: 1px solid #af2eff;

        &::before {
          transform: scaleZ(1);
        }

        .buttonText {
          transform: translate3d(0, -1.3em, 0);
          color: white;

          &:last-child {
            transform: translate3d(0, -32px, 0);
          }
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      width: 101%;
      height: 110%;
      background: #af2eff;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.1, 1);
      transform: scale3d(1, 0, 1);
      transform-origin: bottom;
    }

    .buttonTexts {
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      top: 0.1em;
      font-family: var(--font-sora);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      .buttonText {
        font-family: var(--font-sora);
        margin: 0;
        position: relative;
        z-index: 1;
        bottom: 0.1em;
        transition:
          color 0.3s cubic-bezier(0.4, 0, 0.1, 1),
          transform 0.3s cubic-bezier(0.4, 0, 0.1, 1);

        &:last-child {
          position: absolute;
          width: 100%;
          top: 32px;
          height: 100%;
        }
      }
    }
  }
}
